import { Injectable } from '@angular/core';
// @ts-ignore
import { UniversalAnalytics } from 'google.analytics/index';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  constructor() {}

  /**
   * @description
   * Returns tracking code for Google Analytics which looks like '_ga=xxxxxx'
   */
  public getGALinkerParam(): string {
    const ga: UniversalAnalytics.ga = window[window['GoogleAnalyticsObject']];
    let linkerParam: string;
    if (ga && ga.getAll) {
      linkerParam = ga.getAll()[0].get('linkerParam');
    }
    return linkerParam;
  }
}
