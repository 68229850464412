import { Location } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings
} from '@gilsdav/ngx-translate-router';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ClipboardModule } from 'ngx-clipboard';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { AnnouncementComponent } from './components/announcement/announcement.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CookieAlertComponent } from './components/cookie-alert/cookie-alert.component';
import { ErrorComponent } from './components/error/error.component';
import { FooterBottomComponent } from './components/footer-bottom/footer-bottom.component';
import { FooterSocialComponent } from './components/footer-social/footer-social.component';
import { FooterTopComponent } from './components/footer-top/footer-top.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { MasterComponent } from './components/master/master.component';
import { ModalComponent } from './components/modal/modal.component';
import { PopupComponent } from './components/popup/popup.component';
import { ContentEditorModule } from './modules/content-editor/content-editor.module';
import { SharedModule } from './modules/shared/shared.module';
import { TabsModule } from './modules/tabs/tabs.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function HttpLoaderFactory(
  translate: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings,
  http: HttpClient
) {
  return new LocalizeRouterHttpLoader(translate, location, settings, http);
}

export function CurrentLanguage() {
  let defaultLang = 'en';
  const domain = location.hostname.split('.').pop();
  if (domain === 'cn' && !localStorage.getItem('IS_VISITED_CN_SITE')) {
    defaultLang = 'sc';
    localStorage.setItem('IS_VISITED_CN_SITE', '1');
  } else if (
    domain === 'cn' &&
    localStorage.getItem('IS_VISITED_CN_SITE') === '1' &&
    localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE')
  ) {
    defaultLang = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
  } else if (
    domain !== 'cn' &&
    !localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE')
  ) {
    defaultLang = 'en';
  } else if (
    domain !== 'cn' &&
    localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE')
  ) {
    defaultLang = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
  }
  return defaultLang;
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    MasterComponent,
    HeaderComponent,
    AnnouncementComponent,
    ModalComponent,
    FooterComponent,
    FooterTopComponent,
    FooterBottomComponent,
    FooterSocialComponent,
    CookieAlertComponent,
    BreadcrumbComponent,
    PopupComponent,
    ErrorComponent
  ],
  imports: [
    ClipboardModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(routes),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: HttpLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
      },
      defaultLangFunction: CurrentLanguage
    }),
    ContentEditorModule,
    SharedModule,
    TabsModule
  ],
  exports: [],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule {}
