import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss']
})
export class SocialShareComponent implements OnInit {
  @Input() socialShareData;
  public isCopied = false;
  public isMobile = false;
  public currentURL = window.location.href.replace(
    window.location.protocol + '//',
    ''
  );
  public currenLang = this.translate.currentLang;
  public weiboUrl = `http://service.weibo.com/share/share.php?url=${
    this.currentURL
  }`;

  constructor(
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currenLang = this.translate.currentLang;
    if (typeof this.socialShareData.data === 'object') {
      if (this.socialShareData.data['og:title']) {
        this.weiboUrl += `&title=${this.socialShareData.data['og:title']}`;
      }
      if (this.socialShareData.data['og:image']) {
        this.weiboUrl += `&pic=${this.socialShareData.data['og:image']}`;
      }
    }
    this.detectDevice();

    this.translate.onLangChange.subscribe(() => {
      if (!this.changeDetectorRef['destroyed']) {
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    console.log('event2', event);
    this.detectDevice();
  }
  detectDevice() {
    this.isMobile = window.innerWidth > 1024 ? false : true;
  }
  openLink(url: string, uri?: string) {
    if (uri) {
      url = `${url}/${encodeURIComponent(uri)}`;
    }
    window.open(url, 'newwindow', 'width=1050, height=503, resizable=0');
  }
}
