import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment-timezone';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, skip } from 'rxjs/operators';
import { LazyService } from 'src/app/lazy.service';
import { FooterContentService } from 'src/app/services/footer-content.service';
import { HeaderService } from 'src/app/services/header.service';
import { BookingFormService } from '../../services/booking-form.service';

declare var gtag;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('navigation') navigation: ElementRef;

  @ViewChild('desktopBookingForm', { read: ViewContainerRef })
  desktopBookingForm: ViewContainerRef;

  @ViewChild('mobileBookingForm', { read: ViewContainerRef })
  mobileBookingForm: ViewContainerRef;

  @ViewChild('headerElement')
  headerElement: ElementRef;

  close$ = new BehaviorSubject<Boolean>(false);
  timezone = 'Asia/Manila';
  announcementStartTime: any;
  announcementEndTime: any;
  header$: Observable<Object>;
  header: any;
  footer$: Observable<Object>;
  error: boolean;
  currentLanguage: string;
  showLanguageOptions = false;
  hideHeader = false;
  showAnnouncement = false;
  isDrawerOpen = false;
  headerScrollTop = 0;
  @Input() content;
  @Input() afterOpen;
  @Input() buttonDesign;
  open = false;
  status = false;
  get currentDate() {
    const date = new Date(
      moment.tz(moment(), this.timezone).format('YYYY-MM-DDTHH:mm:ss')
    );
    return this.dateFormatter(date);
  }
  private dateFormatter(date) {
    return `${date.getFullYear()}-${this.normalizeDate(
      date.getMonth() + 1
    )}-${this.normalizeDate(date.getDate())} ${this.normalizeDate(
      date.getHours()
    )}:${this.normalizeDate(date.getMinutes())}:${this.normalizeDate(
      date.getSeconds()
    )}`;
  }
  private normalizeDate(val) {
    return val.toString().padStart(2, '0');
  }

  constructor(
    private headerService: HeaderService,
    private translate: TranslateService,
    private localize: LocalizeRouterService,
    private changeDetectorRef: ChangeDetectorRef,
    private footerService: FooterContentService,
    private router: Router,
    private lazy: LazyService,
    private bookingService: BookingFormService
  ) {
    this.headerService.language$.subscribe(
      language => {
        this.currentLanguage = language;
      },
      err => {
        console.log(err);
        this.currentLanguage =
          this.translate.currentLang || this.translate.defaultLang || 'en';
      }
    );

    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        // Close the drawer menu
        this.isDrawerOpen = false;
        document.querySelector('body').classList.remove('header__drawer--open');

        if (this.header && this.header.google && this.header.google.ga) {
          gtag('config', this.header.google.ga, {
            page_path: e.urlAfterRedirects
          });
        }
        if (this.navigation) {
          this.navigation.nativeElement.classList.remove('active');
        }
      }
    });
  }

  // Open or Close modal
  changeState(event) {
    event.preventDefault();
    event.stopPropagation();

    this.open = !this.open;
    const modalComponent = this;

    if (this.open && this.afterOpen) {
      setTimeout(() => {
        if (this.afterOpen.length > 1) {
          modalComponent.afterOpen[0](this.afterOpen[1]);
        }
      }, 100);
    }
  }

  // Open booking form on click of button.
  clickBook() {
    this.status = true;
    document.body.classList.add('modal-open');
    let container = this.desktopBookingForm;
    if (this.isMobile) {
      container = this.mobileBookingForm;
    }
    this.lazy.loadAndRenderComponents(
      {
        components: [{ type: 'booking_form', data: { close: this.close$ } }]
      },
      container
    );
  }

  closeButton() {
    this.close$.next(true);
  }
  // Close on ESC
  @HostListener('window:keyup', ['$event'])
  close(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeButton();
    }
  }

  ngOnInit() {
    this.close$.pipe(skip(1)).subscribe(() => {
      this.status = false;
      document.body.classList.remove('modal-open');
    });
    this.header$ = this.headerService
      .getHeader(this.translate.currentLang)
      .pipe(
        catchError(err => {
          console.error(err);
          this.error = true;
          return of(err);
        })
      );

    this.header$.subscribe((v: any) => {
      this.header = v;

      this.announcementStartTime = this.convertUTCDateTime(
        v.popup.announcement_start
      );
      this.announcementEndTime = this.convertUTCDateTime(
        v.popup.announcement_end
      );

      if (v.google && v.google.gtm) {
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        });
        const script = document.createElement('script');
        script.async = true;
        script.src =
          'https://www.googletagmanager.com/gtm.js?id=' + v.google.gtm;
        document.head.insertBefore(script, document.head.firstChild);
        window['dataLayer'].push({ userEmail: '$userEmail' });
      }

      if (v.google && v.google.ga) {
        const script = document.createElement('script');
        script.async = true;
        script.src =
          'https://www.googletagmanager.com/gtag/js?id=' + v.google.ga;
        document.head.prepend(script);
        gtag('config', v.google.ga, {
          page_path: window.location.search
        });
      }

      if (v.facebook_pixel_id) {
        const pixelScript: HTMLElement = document.createElement('script');
        pixelScript.innerText =
          '!function(e,t,n,c,o,a,f){e.fbq||(o=e.fbq=function(){o.callMethod?o.callMethod.apply(o,arguments):o.queue.push(arguments)},e._fbq||(e._fbq=o),o.push=o,o.loaded=!0,o.version="2.0",o.queue=[],(a=t.createElement(n)).async=!0,a.src="https://connect.facebook.net/en_US/fbevents.js",(f=t.getElementsByTagName(n)[0]).parentNode.insertBefore(a,f))}(window,document,"script"),fbq("init","' +
          v.facebook_pixel_id +
          '"),fbq("track","PageView");';
        document.head.insertBefore(pixelScript, document.head.lastChild);
      }

      if (v.facebook_pixel_id) {
        const pixelNoScript: HTMLElement = document.createElement('noscript');
        document.head.insertBefore(pixelNoScript, document.head.lastChild);
        const pixelNoScriptImg: HTMLElement = document.createElement('img');
        pixelNoScriptImg.setAttribute('height', '1');
        pixelNoScriptImg.setAttribute('width', '1');
        pixelNoScriptImg.setAttribute('style', 'display:none');
        pixelNoScriptImg.setAttribute(
          'src',
          'https://www.facebook.com/tr?id=' +
            v.facebook_pixel_id +
            '&ev=PageView&noscript=1'
        );
        pixelNoScript.insertAdjacentElement('beforeend', pixelNoScriptImg);
      }

      if (v.booking && v.booking.site_minder) {
        this.bookingService.siteMinderSettings.next({
          propertyCode: v.booking.site_minder.property_code,
          regionCode: v.booking.site_minder.region_code
        });
      }
    });

    this.footer$ = this.footerService
      .getContent(this.translate.currentLang)
      .pipe(
        catchError(err => {
          console.error(err);
          this.error = true;
          return of(err);
        })
      );

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (!this.changeDetectorRef['destroyed']) {
        this.header$ = this.headerService.getHeader(event.lang).pipe(
          catchError(err => {
            console.error(err);
            this.error = true;
            return of(err);
          })
        );

        this.header$.subscribe((v: any) => {
          this.header = v;
          this.announcementStartTime = this.convertUTCDateTime(
            v.popup.announcement_start
          );
          this.announcementEndTime = this.convertUTCDateTime(
            v.popup.announcement_end
          );
        });

        this.footer$ = this.footerService
          .getContent(this.translate.currentLang)
          .pipe(
            catchError(err => {
              console.error(err);
              this.error = true;
              return of(err);
            })
          );
        this.changeDetectorRef.detectChanges();
      }
    });

    if (!window.sessionStorage.getItem('isAnnouncementClosed')) {
      this.showAnnouncement = true;
    }

    // Add font to body as per language
    document.body.classList.add(this.translate.currentLang);

    // Subscribe to book now opener
    this.headerService.bookNowOpenerStream$.subscribe(() => {
      this.clickBook();
    });
  }

  toggleMenu(navigation: HTMLDivElement) {
    navigation.classList.toggle('active');
    document.body.classList.toggle('overflow-hidden');
  }

  toggleChild(event, children) {
    if (this.isMobile && !!children) {
      event.currentTarget.parentElement.parentElement
        .querySelector('.header__menu-child')
        .classList.toggle('open-child');
      event.currentTarget
        .querySelector('span')
        .classList.toggle('sub-menu-is-open');
      event.currentTarget.parentElement
        .querySelector('.header__menu-link')
        .classList.toggle('header__menu-link--open');
    }
  }

  toggleLanguageOptions(event) {
    event.stopPropagation();
    this.showLanguageOptions = !this.showLanguageOptions;
  }

  @HostListener('document:click', ['$event.target'])
  onClick() {
    if (this.showLanguageOptions) {
      this.showLanguageOptions = false;
    }
  }

  toggleDrawer() {
    this.isDrawerOpen = !this.isDrawerOpen;

    if (this.isDrawerOpen) {
      document.querySelector('body').classList.add('header__drawer--open');
    } else {
      document.querySelector('body').classList.remove('header__drawer--open');
    }
  }

  toggleWeChatModal(event) {
    event.preventDefault();
    const modal_button = document.querySelector(
      '.modal__button-open'
    ) as HTMLElement;
    modal_button.click();
  }

  isCurrentPath(link) {
    return window.location.pathname.endsWith(link);
  }

  get isMobile() {
    return window.matchMedia('(max-width: 1199px)').matches;
  }

  get isDesktop() {
    return window.matchMedia('(min-width: 1025px)').matches;
  }

  handleChildClick(event) {
    if (!this.isMobile) {
      this.hideDropDown(event);
    }
  }

  showDropDown(event) {
    event.target.parentElement.parentElement.classList.add('show-menu-child');
  }

  hideDropDown(event) {
    event.target.parentElement.parentElement.classList.remove(
      'show-menu-child'
    );

    event.target.parentElement.parentElement.parentElement.classList.remove(
      'sub-menu-is-open'
    );
  }

  onLanguageChange(lang) {
    this.showLanguageOptions = !this.showLanguageOptions;
    this.headerService.languageChanged(lang);
    document.body.classList.remove(this.translate.currentLang);
    this.useLanguage(lang);
    document.body.classList.add(this.currentLanguage);
  }

  useLanguage(language: string) {
    this.localize.changeLanguage(language);
  }

  closeAnnouncement() {
    this.showAnnouncement = false;
    window.sessionStorage.setItem('isAnnouncementClosed', 'true');
  }

  @HostListener('window:scroll', ['$event'])
  scrollHeader() {
    // For desktop only
    if (window.innerWidth > 1024) {
      const windowScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      // If scrolling up show the header, if down past 153px then hide the header
      if (this.headerScrollTop > windowScrollTop) {
        this.hideHeader = false;
      } else if (
        this.headerScrollTop < windowScrollTop &&
        this.headerScrollTop > 153
      ) {
        this.hideHeader = true;
      }

      // Update current scroll position of header
      this.headerScrollTop = windowScrollTop <= 0 ? 0 : windowScrollTop;
    }
  }

  convertUTCDateTime(dateTime) {
    if (dateTime) {
      const utcDate = moment.utc(dateTime, 'YYYYMMDD HH:mm:ss');
      const convertDate = utcDate.clone().tz(this.timezone);
      return this.dateFormatter(
        new Date(convertDate.format('YYYY-MM-DDTHH:mm:ss'))
      );
    }
  }
}
