import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';

/**
 * Class to manipulate DOM
 * Yout can refactor the existing code to use this class instaed of a direct DOM manipulation
 */
@Injectable({
  providedIn: 'root'
})
export class DomService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Append the JS tag to the Document Body.
   * Not used
   * @param renderer The Angular Renderer
   * @param src The path to the script
   * @returns the script element
   */
  public loadJsScript(renderer: Renderer2, src: string): HTMLScriptElement {
    const script = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    renderer.appendChild(this.document.body, script);
    return script;
  }

  public hasIndexHTMLTag(): boolean {
    console.log(
      'ROBOTS TAG IS',
      document.head.querySelector('meta[name="robots"]')
    );
    return !(document.head.querySelector('meta[name="robots"]') == null);
  }
  /**
   * Sets the lang attribute in the HTML (root) page tag
   * @param lang lang name
   * @returns void
   */
  public setNoIndexHTMLTag(): void {
    const node = document.createElement('meta');
    node.setAttribute('name', 'robots');
    node.setAttribute('content', 'noindex');
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  /**
   * Sets the lang attribute in the HTML (root) page tag
   * @param lang lang name
   * @returns void
   */
  public setHTMLTagLang(lang: string): void {
    const htmlNode = this.document.getElementsByTagName('html')[0];
    htmlNode.setAttribute('lang', lang);
  }
}
