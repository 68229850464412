import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { GoogleAnalyticsService } from './google-analytics.service';

export interface BookingFormModelInterface {
  numberOfRooms: number;
  numberOfAdults: number;
  numberOfChildren: number;
  checkInDate: Date;
  checkOutDate: Date;
  locale: string;
  numberOfInfants: number;
  currency: string;
  trackingCode?: string;
  googleAnalyticsCode?: string;
  addLeadZero(number: number): string;
  isCorrect(): boolean;
  getErrorMessage(): string;
  getDateForSiteMinder(theDate: Date): string;
}

class RoomReservation {
  constructor(
    private numberOfAdults,
    private numberOfChildren,
    private numberOfInfants
  ) {}

  public toString(i) {
    return `&items[${i}][adults]=${this.numberOfAdults}&items[${i}][children]=${
      this.numberOfChildren
    }&items[${i}][infants]=${this.numberOfInfants}`;
  }
}

@Injectable({
  providedIn: 'root'
})
export class BookingFormService {
  private regionCode: string;
  private propertyCode: string;
  public siteMinderSettings = new Subject();
  private siteMinderLocales = {
    en: 'en_US',
    sc: 'zh-CN',
    tc: 'zh-TW'
  };

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private translate: TranslateService
  ) {
    this.siteMinderSettings.pipe(take(1)).subscribe((value: any) => {
      this.regionCode = value.regionCode;
      this.propertyCode = value.propertyCode;
      this.siteMinderSettings.complete(); // pris is general site-wide value, so we need only first value and then unsubsribe
    });
  }

  /**
   * Returns a link with added analytics parameters. Can be used to pass GA , Ocean Engine parameters to external systems wia the URL
   * If there are Linker option for Google Anal library, then we don't want to include GA params , because they will auto added
   */
  public getWithAdditionalParameters(url: string, addGa: boolean): string {
    let httpParams: HttpParams;

    if (addGa) {
      const gaLinkerParam = this.googleAnalyticsService.getGALinkerParam();
      httpParams = new HttpParams({ fromString: gaLinkerParam });
    } else {
      httpParams = new HttpParams();
    }
    if (httpParams.keys().length) {
      // There are can be auto-added by GA Linker, for ex, or just hard-coded values
      if (url.indexOf('?') === -1) {
        url = url + '?';
      } else {
        url = url + '&';
      }

      url = url + httpParams.toString();
    }
    return url;
  }

  public getLocaleByLangCode(currentLang: string): string {
    if (!this.siteMinderLocales[currentLang]) {
      throw new Error('Cannot find locale for lang ' + currentLang);
    }

    return this.siteMinderLocales[currentLang];
  }

  public getSiteMinderLink(bookingFormData: BookingFormModelInterface): string {
    if (!bookingFormData.googleAnalyticsCode) {
      bookingFormData.googleAnalyticsCode = this.googleAnalyticsService.getGALinkerParam();
    }
    bookingFormData.locale = this.getLocaleByLangCode(
      this.translate.currentLang
    );

    bookingFormData.trackingCode = 'no';

    let siteMinderLink = `https://${this.regionCode}/properties/${
      this.propertyCode
    }?locale=${bookingFormData.locale}`;

    for (let i = 0; i < bookingFormData.numberOfRooms; i++) {
      siteMinderLink = siteMinderLink.concat(
        new RoomReservation(
          bookingFormData.numberOfAdults,
          bookingFormData.numberOfChildren,
          bookingFormData.numberOfInfants
        ).toString(i)
      );
    }
    siteMinderLink = siteMinderLink.concat(
      `&currency=${
        bookingFormData.currency
      }&checkInDate=${bookingFormData.getDateForSiteMinder(
        bookingFormData.checkInDate
      )}&checkOutDate=${bookingFormData.getDateForSiteMinder(
        bookingFormData.checkOutDate
      )}&trackPage=${bookingFormData.trackingCode}&${
        bookingFormData.googleAnalyticsCode
      }`
    );
    console.log(siteMinderLink);
    return siteMinderLink;
  }
}
