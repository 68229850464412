import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { LazyService } from 'src/app/lazy.service';
import { BackgroundService } from 'src/app/services/background.service';
import { MetaTagsService } from 'src/app/services/meta-tags.service';
import { PageService } from 'src/app/services/page.service';
import { DomService } from '../../services/dom.service';
import { MelcoLocales } from '../../utils/locales';
import { ErrorComponent } from '../error/error.component';
@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent {
  @ViewChild('dynamicComponents', { read: ViewContainerRef })
  container: ViewContainerRef;

  pageSubscription;
  data;
  IsShowSocialShareIcon = true;
  showSocialShare = false;
  socialShareData: any = {};
  currentURL = window.location.href;
  hash: string;
  oldSiteLinks = [
    'special-offers.html',
    'special-offers-rooms.html',
    'special-offers-dining.html',
    'special-offers-spa.html',
    'rooms.html',
    'rooms/detail/1/Villas.html',
    'rooms/detail/9/Corner-Suites.html',
    'rooms/detail/6/Premier-Suites.html',
    'rooms/detail/12/Waterfront-View-Rooms.html',
    'dining.html',
    'dining/detail/1/aurora.html',
    'dining/detail/12/Tenmasa.html',
    'dining/detail/15/Ying.html',
    'dining/detail/21/38-Lounge.html',
    'dining/detail/24/Monsoon.html',
    'dining/detail/9/kira.html',
    'yi-pavilion.html',
    'entertainment.html',
    'entertainment/detail/1/Live-music-at-38-lounge.html',
    'entertainment/detail/6/melcoclub.html',
    'spa.html',
    'spa/detail/1/Altira-Spa.html',
    'spa/detail/17/Fitness-Center.html',
    'spa/detail/11/Swimming-Pool.html',
    'spa/detail/8/TheHairBeautySalon.html',
    'spa/detail/14/PoolBar.html',
    'event-planner.html',
    'about-us/the-hotel.html',
    'about-us/recognition.html',
    'about-us/floor-guide.html',
    'about-us/getting-here.html',
    'about-us/macau-info.html',
    'gallery.html',
    'page/detail/15/Newspaper.html'
  ];

  newSiteLinks = [
    'special-offers',
    'special-offers-rooms',
    'special-offers-dining',
    'special-offers-spa',
    'rooms',
    'rooms/villas',
    'rooms/Corner-Suites',
    'rooms/Premier-Suites',
    'rooms/Waterfront-View-Rooms',
    'dining',
    'dining/aurora',
    'dining/Tenmasa',
    'dining/Ying',
    'dining/38-Lounge',
    'dining/monsoon',
    'dining/kira',
    'dining/yi-pavilion',
    'entertainment',
    'entertainment/Live-music-at-38-lounge',
    'entertainment/Melco-Club',
    'spa',
    'spa/altira-spa',
    'spa/fitness-center',
    'spa/swimming-pool',
    'spa/the-hair-beauty-salon',
    'spa/pool-bar',
    'event-planner',
    'about-us/the-hotel',
    'about-us/recognition',
    'about-us/floor-guide',
    'about-us/getting-here',
    'about-us/macau-info',
    'gallery',
    'newspaper'
  ];

  constructor(
    private page: PageService,
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private lazy: LazyService,
    private metaTags: MetaTagsService,
    private background: BackgroundService,
    private domService: DomService,
    private resolver: ComponentFactoryResolver
  ) {
    this.activatedRoute.params.subscribe(() => {
      this.generateURL();
      this.pageSubscription = this.page
        .getPage(this.generateURL(), this.translate.currentLang)
        .subscribe(
          (data: any) => {
            this.domService.setHTMLTagLang(
              MelcoLocales.getISO639CodeByLocale(this.translate.currentLang)
            );
            /* Lets inject social share data into each component to show it on each page as each component wants */
            data = this.addSocialShareDataToComponent(data);

            /* But also lets set up social share data for master component (whole application, just in case) */
            this.showSocialShare = data.show_social_share_links;
            if (data.show_social_share_links) {
              this.setSocialShareData(data);
            }

            /** Actually render all components */
            this.renderComponents(data);
          },
          e => {
            this.handleError(e, this.generateURL(), this.translate.currentLang);
          }
        );

      this.translate.onLangChange.subscribe(() => {
        if (!this.changeDetectorRef['destroyed']) {
          this.pageSubscription = this.page
            .getPage(this.generateURL(), this.translate.currentLang)
            .subscribe(
              (data: any) => {
                this.domService.setHTMLTagLang(
                  MelcoLocales.getISO639CodeByLocale(this.translate.currentLang)
                );
                this.renderComponents(data);
              },
              e => {
                this.handleError(
                  e,
                  this.generateURL(),
                  this.translate.currentLang
                );
              }
            );

          this.changeDetectorRef.detectChanges();
        }
      });
    });

    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.navigation(e);
      });
  }
  /* Injecting social share data into each component */
  protected addSocialShareDataToComponent(data: any) {
    data.components = data.components.map(componentData => {
      componentData.sn_share_data = {
        showSocialShare: data.show_social_share_links,
        data: data.metatags,
        links: data.social_share_links
      };
      return componentData;
    });
    return data;
  }

  renderComponents(data: any) {
    this.data = data;
    this.showSocialShare = data.show_social_share_links;
    /* We already set up this data above */
    // if (data.show_social_share_links) {
    //   this.setSocialShareData(data);
    // }
    this.metaTags.setMetatags(data);
    this.dataToComponents(data);
    setTimeout(() => {
      this.jumpToHash();
    }, 500);
  }

  handleError(e: HttpErrorResponse, requestedURL: string, language: string) {
    if (e.status > 299) {
      const index = this.oldSiteLinks.indexOf(this.generateURL().slice(0, -1));
      if (index !== -1) {
        this.router.navigate([
          `${this.translate.currentLang}/${this.newSiteLinks[index]}/`
        ]);
      } else {
        const factory = this.resolver.resolveComponentFactory(ErrorComponent);
        const componentRef = this.container.createComponent(factory);
        componentRef.instance.data = {
          httpCode: e.status,
          message: e.message,
          statusText: e.statusText,
          language: language,
          requestedURL: requestedURL
        };
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  jumpToHash() {
    const element: HTMLElement = document.querySelector(
      '#' + decodeURIComponent(this.hash)
    );
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      this.hash = null;
    }
  }

  /*
   Generates the uri path of the page to request via API
   As reduce adds the "/" at the end of str, we need to remove it
   */
  generateURL() {
    const url = this.activatedRoute.snapshot.url.reduce(
      (a, i) => (a += i.path + '/'),
      ''
    );
    return url.substr(0, url.length - 1);
  }

  dataToComponents(data: any) {
    if (
      data.no_indexing_se &&
      data.no_indexing_se === '1' &&
      !this.domService.hasIndexHTMLTag()
    ) {
      this.domService.setNoIndexHTMLTag();
    }
    this.lazy.loadAndRenderComponents(data, this.container);
  }

  setBackground() {
    return this.background.setBackground(this.data);
  }

  setSocialShareData(data) {
    this.socialShareData.currentURL = window.location.href;
    this.socialShareData.data = data.metatags;
    this.socialShareData.links = data.social_share_links;
  }

  navigation(e) {
    if (e.url.indexOf('#') > -1) {
      this.hash = e.url.split('#')[1];
    }
  }
}
