import { Injectable } from '@angular/core';

import {
  Inject,
  InjectionToken,
  Injector,
  NgModuleFactory,
  NgModuleFactoryLoader,
  Type
} from '@angular/core';
import { Dynamic } from './components/dynamic';

export interface LazyModules {
  [key: string]: string;
}

export const lazyMap: LazyModules = {
  content:
    'src/app/modules/content-editor/content-editor.module#ContentEditorModule',
  album: 'src/app/modules/album/album.module#AlbumModule',
  background: 'src/app/modules/background/background.module#BackgroundModule',
  carousel_kv:
    'src/app/modules/kv-carousel/kv-carousel.module#KvCarouselModule',
  '2_column': 'src/app/modules/column/column.module#ColumnModule',
  '3_column': 'src/app/modules/column/column.module#ColumnModule',
  highlight_list:
    'src/app/modules/highlight-list/highlight-list.module#HighlightListModule',
  level_menu: 'src/app/modules/level-menu/level-menu.module#LevelMenuModule',
  maps: 'src/app/modules/map/map.module#MapModule',
  story: 'src/app/modules/story/story.module#StoryModule',
  tabs: 'src/app/modules/tabs/tabs.module#TabsModule',
  video: 'src/app/modules/video/video.module#VideoModule',
  booking_form:
    'src/app/modules/booking-form/booking-form.module#BookingFormModule'
};

export const LAZY_MODULES_MAP = new InjectionToken('LAZY_MODULES_MAP', {
  factory: () => lazyMap
});

export type ModuleWithRoot = Type<any> & { rootComponent: Type<any> };

@Injectable({
  providedIn: 'root'
})
export class LazyService {
  constructor(
    private injector: Injector,
    private loader: NgModuleFactoryLoader,
    @Inject(LAZY_MODULES_MAP) private modulesMap
  ) {}

  async loadAndRenderComponents(data, container) {
    container.clear();

    /*we are extracting some objects into variables to make possible create and configure our service
     * We are expecting that for each component there are "data" and "type" fields with components data
     * But there are may be some additional fields
     * "tab_design" - variable for "tab" component located on level "data" and "type" fields, so we need extract it like {... tab_design..}
     *
     * */
    for await (const {
      type,
      data: componentData,
      tab_design,
      sn_share_data
    } of data.components) {
      const moduleFactory: NgModuleFactory<any> = await this.loader.load(
        this.modulesMap[type]
      );
      const moduleRef: any = moduleFactory.create(this.injector);
      const rootComponent = (moduleFactory.moduleType as ModuleWithRoot)
        .rootComponent;
      const factory = moduleRef.componentFactoryResolver.resolveComponentFactory(
        rootComponent
      );
      const componentRef = container.createComponent(factory);
      (componentRef.instance as Dynamic).data = componentData;

      /* The "Tab" and "column" component has some specific option, so lets set it for just created component
       * May be it will be better to add additional variable to data variable, but options field looks good for now*/
      if (type === 'tabs' || type === '2_column') {
        (componentRef.instance as Dynamic).options = new Map<string, any>();
        (componentRef.instance as Dynamic).options.set('design_id', tab_design);
        (componentRef.instance as Dynamic).options.set(
          'sn_share_data',
          sn_share_data
        );
      }
    }
  }
}
