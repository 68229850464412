import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
interface HTTPErrorDetails {
  httpCode: number;
  message: string;
  statusText: string;
  language: string;
  requestedURL: string;
}

interface ErrorDetails extends HTTPErrorDetails {
  os: string;
  browserString: string;
  time: Date;
  screenResolutionString: string;
}
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, AfterViewInit {
  // private previousUrl: string;
  @Input() data: HTTPErrorDetails;

  public errorWithDetails: ErrorDetails;
  ERROR_DISPLAY_TIMEOUT = 5000; // in ms

  ngAfterViewInit() {
    setTimeout(() => {
      this.goToHomepage();
    }, this.ERROR_DISPLAY_TIMEOUT);
  }

  goToHomepage() {
    this.router.navigateByUrl('/');
  }
  constructor(
    private clipboardService: ClipboardService,
    private router: Router
  ) {}
  ngOnInit() {
    this.errorWithDetails = <ErrorDetails>this.data;
    this.errorWithDetails.os = navigator.platform;
    this.errorWithDetails.browserString = navigator.userAgent;
    this.errorWithDetails.screenResolutionString =
      window.innerWidth + ' X ' + window.innerHeight;
    this.errorWithDetails.time = new Date();
  }
  getTextErrorDescrtiption(): string {
    return `THE SITE ERROR REPORT\n
     Requested URL: ${this.errorWithDetails.requestedURL}\n
     Server response code: ${this.errorWithDetails.httpCode}\n
     Server response text: ${this.errorWithDetails.message}\n
     Server response status: ${this.errorWithDetails.statusText}\n
     Error time ${this.errorWithDetails.time.toString()}\n
     OS: ${this.errorWithDetails.os}\n
     Browser version: ${this.errorWithDetails.browserString}\n
     Screen resolution: ${this.errorWithDetails.screenResolutionString}}\n
     `;
  }

  copyErrorDetails() {
    const text = this.getTextErrorDescrtiption();

    if (this.clipboardService.isSupported) {
      this.clipboardService.copyFromContent(text);
    } else {
      alert(
        'Unfortunately your browser does not support working with a clipboard'
      );
    }
  }
}
