import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-social',
  templateUrl: './footer-social.component.html',
  styleUrls: ['./footer-social.component.scss']
})
export class FooterSocialComponent implements OnInit {
  @Input() data;

  ngOnInit() {}

  toggleWeChatModal(event) {
    event.preventDefault();
    const modal_button = document.querySelector(
      '.modal__button-open'
    ) as HTMLElement;
    modal_button.click();
  }
}
