import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { LazyService } from 'src/app/lazy.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  @ViewChild('activeTab', { read: ViewContainerRef })
  container: ViewContainerRef;
  @Input() data;
  @Input() options: Map<string, any>;
  selectedTabIndex = 0;
  isSubMenuOpen = false;
  private designsMap = new Map<string, string>()
    .set('', 'desing_old')
    .set('2', 'revamp')
    .set('1', 'design_old');
  public designClass: string;

  public socialShareData: any = {};
  public showSocialShare = false;

  public selectedTab: { index: number; name: string };

  constructor(private lazy: LazyService) {}

  ngOnInit() {
    /* Set class for DIV wrapper basing on design id */
    const designId = this.options.get('design_id');
    this.designClass = this.designsMap.get(designId);
    this.socialShareData = this.options.get('sn_share_data');
    /*Setting up social share data*/
    if (this.socialShareData) {
      this.showSocialShare = this.socialShareData.showSocialShare;
      this.socialShareData.currentURL = window.location.href;
    }
    /*Rendering tabs*/
    this.data = this.data[0].tabs;
    this.lazy.loadAndRenderComponents(
      { components: this.data[0].tab_content },
      this.container
    );

    this.selectedTab = {
      index: 0,
      name: this.data[0].tab_heading
    };
  }

  switchTab(index) {
    if (index === this.selectedTabIndex) {
      return false;
    }

    this.selectedTabIndex = index;

    this.selectedTab = {
      index: index,
      name: this.data[index].tab_heading
    };

    this.lazy.loadAndRenderComponents(
      { components: this.data[index].tab_content },
      this.container
    );

    this.isSubMenuOpen = false;
  }

  toggleSubMenu(event) {
    event.stopPropagation();

    this.isSubMenuOpen = !this.isSubMenuOpen;
  }
}
