import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cookie-alert',
  templateUrl: './cookie-alert.component.html',
  styleUrls: ['./cookie-alert.component.scss']
})
export class CookieAlertComponent implements OnInit {
  @Output() accept = new EventEmitter<boolean>();
  @Input() cookieData;
  @Input() buttonText;
  showError: boolean;
  footerCopyrightData = { body: '' };
  displayCookieAlert = true;

  ngOnInit() {
    if (this.cookieData) {
      this.footerCopyrightData.body = this.cookieData;
    } else {
      this.footerCopyrightData.body = 'no cooke consent message found';
      this.displayCookieAlert = false;
    }
  }

  onCookieConfirm() {
    this.showError = false;
    this.accept.emit(true);
  }
}
