export class CnSites {
  constructor() {}

  public static isCnSiteOpened(): boolean {
    const urlParams = new URLSearchParams(window.location.search);
    return (
      window.location.href.indexOf('.cn') !== -1 ||
      urlParams.get('cn-site') !== null
    );
  }

  public static isMelvisImitation(): boolean {
    const urlParams = new URLSearchParams(window.location.search);
    const isMelvis = urlParams.get('melvis') === '1';
    return isMelvis;
  }
}
export default CnSites;
