import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() data;

  getButtonClasses(classes) {
    return ['altira-button'].concat(classes);
  }
}
