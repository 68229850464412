import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { HeaderService } from 'src/app/services/header.service';

interface FooterShortcutItems {
  items: FooterShortcut[];
}
interface FooterData {
  copyright: any;
  footer_menu: any;
  shortcuts: FooterShortcutItems;
}

interface FooterShortcut {
  image: string;
  'is-icon': boolean;
  link: string;
  text: string;
}

@Component({
  selector: 'app-footer-bottom',
  templateUrl: './footer-bottom.component.html',
  styleUrls: ['./footer-bottom.component.scss']
})
export class FooterBottomComponent implements OnInit {
  @Input() data: FooterData;
  // homePage = false;
  locCn = false;
  header$: Observable<Object>;
  error: boolean;

  constructor(
    private router: Router,
    private headerService: HeaderService,
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isLocationCn();
    // this.setHomepage();

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        // this.setHomepage();
      });

    this.header$ = this.headerService
      .getHeader(this.translate.currentLang)
      .pipe(
        catchError(err => {
          console.error(err);
          this.error = true;
          return of(err);
        })
      );

    this.translate.onLangChange.subscribe(() => {
      if (!this.changeDetectorRef['destroyed']) {
        this.header$ = this.headerService
          .getHeader(this.translate.currentLang)
          .pipe(
            catchError(err => {
              console.error(err);
              this.error = true;
              return of(err);
            })
          );
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  isLocationCn() {
    if (location.hostname.split('.').pop() === 'cn') {
      this.locCn = true;
    }
  }

  /**
   * Defines if in this.data are present text content
   * @param textBlockNumber number
   */
  hasDataForTextBlock(textBlockNumber: number): boolean {
    return (
      this.data.hasOwnProperty('text_' + textBlockNumber) &&
      this.data['text_' + textBlockNumber].body
    );
  }

  /**
   * Extract data for text block and adding property for "content-editor" component
   * @param textBlockNumber number
   */
  getDataForTextBlock(textBlockNumber: number): any {
    const textBlock: any = this.data['text_' + textBlockNumber];
    textBlock.is_background_transparent = true;
    return textBlock;
  }
}
