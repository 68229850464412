import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LazyService } from 'src/app/lazy.service';

import { PageService } from '../../services/page.service';
import { PopupService } from '../../services/popup.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  @ViewChild('dynamicComponents', { read: ViewContainerRef })
  container: ViewContainerRef;
  currentPage = '';
  showPopup = false;

  constructor(
    private translate: TranslateService,
    private pageService: PageService,
    private popupService: PopupService,
    private lazy: LazyService
  ) {}

  ngOnInit() {
    this.popupService.popupStream$.subscribe(popupPage => {
      if (this.currentPage !== popupPage) {
        this.pageService
          .getPage(popupPage.replace('/', ''), this.translate.currentLang)
          .subscribe(
            (data: any) => {
              this.lazy.loadAndRenderComponents(data, this.container);
              this.open();
              this.currentPage = popupPage;
            },
            e => {
              if (e.status > 299) {
                this.close();
              }
            }
          );
      } else {
        this.open();
      }
    });

    // On language change, set current page empty
    this.translate.onLangChange.subscribe(() => {
      this.currentPage = '';
    });
  }

  close() {
    this.showPopup = false;
    document.body.classList.remove('modal-open');
  }

  open() {
    this.showPopup = true;
    document.body.classList.add('modal-open');
  }

  checkContainerClose($event) {
    if (!document.getElementById('modal-popup').contains($event.target)) {
      this.close();
    }
  }

  // Close on ESC
  @HostListener('window:keyup', ['$event'])
  closeByEscape(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.close();
    }
  }
}
