export class MelcoLocales {
  static melcoLocales = {
    en: 'en',
    sc: 'zh-Hant',
    tc: 'zh-Hans'
  };

  /**
   * Returns locale code (ISO639-1) by Melco (Drupal's) language code
   * @param locale string site locale as it comes from Translate Service (expect it will be the same as lang param in the URL)
   */
  public static getISO639CodeByLocale(locale: string): string | null {
    return this.melcoLocales[locale];
  }
}

export default MelcoLocales;
