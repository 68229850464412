import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FooterContentService {
  private cache$: Observable<Object>;
  private lang: string;
  constructor(private http: HttpClient) {}

  getContent(langCode: string) {
    if (langCode !== this.lang) {
      this.cache$ = null;

      if (!this.cache$) {
        this.cache$ = this.http
          .get(`${environment.baseURL}/${langCode}/footer`)
          .pipe(share());
      }
    }

    this.lang = langCode;

    return this.cache$;
  }
}
