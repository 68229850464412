import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Directive({
  selector: '[appMultilink]'
})
export class MultilinkDirective implements OnInit {
  @Input() url: string;
  @Input() disabled = false;
  fragment = null;
  isInternal = false;
  externalChecks = ['http', 'https', '/sites/', '/media/'];
  languages = ['en', 'sc', 'tc'];

  constructor(
    private element: ElementRef,
    private router: Router,
    private localize: LocalizeRouterService
  ) {}

  ngOnInit() {
    if (!this.url || this.disabled) {
      return;
    }

    this.isInternal = !this.externalChecks.some(i => this.url.startsWith(i));

    if (this.isInternal) {
      this.url = this.localize.translateRoute(
        this.removeLanguagePrefix(this.url)
      ) as string;
    }

    this.setDOMAttributes();

    if (this.isInternal && this.url.includes('#')) {
      const hashLocation = this.url.indexOf('#');
      this.fragment = this.url.slice(hashLocation).slice(1);
      this.url = this.url.slice(0, hashLocation);
    }
  }

  @HostListener('click', ['$event'])
  clickHandler(event: MouseEvent) {
    if (!this.isInternal) {
      return;
    }

    event.preventDefault();
    if (this.fragment) {
      this.router.navigate([this.url], { fragment: this.fragment });
    } else {
      this.router.navigate([this.url]);
    }
  }

  private setDOMAttributes() {
    this.element.nativeElement.setAttribute('target', '_blank');
    this.element.nativeElement.setAttribute('href', this.url);
  }

  private removeLanguagePrefix(url: string) {
    this.languages.forEach(lang => {
      if (url.match(/^\/(en|sc|tc)\//)) {
        url = url.replace(`/${lang}/`, '/');
      } else if (url.length === 3 && url === `/${lang}`) {
        url = '/';
      }
    });

    return url;
  }
}
