import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContentEditorComponent } from 'src/app/components/content-editor/content-editor.component';

import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [ContentEditorComponent],
  imports: [CommonModule, SharedModule],
  exports: [ContentEditorComponent],
  entryComponents: [ContentEditorModule.rootComponent]
})
export class ContentEditorModule {
  static rootComponent = ContentEditorComponent;
}
