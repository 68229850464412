import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { fromEvent } from 'rxjs';
import { map, pairwise, share, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements AfterViewInit {
  public announcementContent: SafeHtml;
  @Output() close = new EventEmitter<boolean>();
  @Input() set content(data: any) {
    if (data) {
      this.announcementContent = this.sanitizer.bypassSecurityTrustHtml(data);
    }
  }

  @ViewChild('announcementWrap') announcementWrapper: ElementRef;
  constructor(private sanitizer: DomSanitizer, private renderer: Renderer2) {}

  ngAfterViewInit() {
    try {
      if (
        document.getElementById('content-wrapper').scrollHeight >
        document.getElementById('content-wrapper').clientHeight
      ) {
        document.body.classList.add('no-scroll');
      }
    } catch (e) {
      console.log(`Unable to calculate element's height`);
    }

    enum Direction {
      Up = 'Up',
      Down = 'Down',
      OnTop = 'OnTop'
    }

    const scroll$ = fromEvent(window, 'scroll').pipe(
      throttleTime(10),
      map(() => window.pageYOffset),
      pairwise(),
      map(
        ([y1, y2]): Direction => {
          if (y2 < 10) {
            return Direction.OnTop;
          } else if (y2 < y1) {
            return Direction.Up;
          } else {
            return Direction.Down;
          }
        }
      ),
      share()
    );
    // Positioning of Announcement component according to header and level menus
    scroll$.subscribe(direction => {
      if (direction === Direction.Up) {
        const levelMenus = document.querySelectorAll('.level-menu.sticky');
        const header = document.getElementsByTagName('header');
        this.renderer.addClass(
          this.announcementWrapper.nativeElement,
          'announcement-wrapper--fixed'
        );
        let totalOffset = 0;
        if (levelMenus.length) {
          totalOffset += this.calculateTopOffset(levelMenus);
        }
        if (header.length) {
          totalOffset += this.calculateTopOffset(header);
        }
        this.renderer.setStyle(
          this.announcementWrapper.nativeElement,
          'top',
          `${totalOffset}px`
        );
      } else if (direction === Direction.OnTop) {
        this.renderer.setStyle(
          this.announcementWrapper.nativeElement,
          'top',
          0
        );
        this.renderer.removeClass(
          this.announcementWrapper.nativeElement,
          'announcement-wrapper--fixed'
        );
      } else if (direction === Direction.Down) {
        this.renderer.addClass(
          this.announcementWrapper.nativeElement,
          'announcement-wrapper--fixed'
        );
        const levelMenus = document.querySelectorAll('.level-menu.sticky');
        if (levelMenus.length) {
          this.renderer.setStyle(
            this.announcementWrapper.nativeElement,
            'top',
            `${this.calculateTopOffset(levelMenus)}px`
          );
        } else {
          this.renderer.setStyle(
            this.announcementWrapper.nativeElement,
            'top',
            0
          );
        }
      }
    });
  }

  onAnnouncementClose() {
    document.body.classList.remove('no-scroll');
    this.close.emit(true);
  }

  calculateTopOffset(elementArray): number {
    let offsetHeight = 0;
    [].forEach.call(elementArray, el => {
      offsetHeight += el.clientHeight;
    });
    return offsetHeight;
  }
}
