import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  LocalizeRouterModule,
  LocalizeRouterPipe
} from '@gilsdav/ngx-translate-router';
import { ShareModule } from '@ngx-share/core';
import { TranslateModule } from '@ngx-translate/core';
import { routes } from 'src/app/app-routing.module';
import { ButtonComponent } from 'src/app/components/button/button.component';
import { MultilinkDirective } from 'src/app/directives/multilink.directive';
import { SocialShareComponent } from '../../components/social-share/social-share.component';

@NgModule({
  declarations: [MultilinkDirective, ButtonComponent, SocialShareComponent],
  imports: [
    CommonModule,
    LocalizeRouterModule.forChild(routes),
    RouterModule,
    TranslateModule.forChild(),
    ShareModule.withConfig({
      windowWidth: 1050
    })
  ],
  exports: [
    MultilinkDirective,
    ButtonComponent,
    LocalizeRouterPipe,
    RouterModule,
    TranslateModule,
    ShareModule,
    SocialShareComponent
  ]
})
export class SharedModule {}
