import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService {
  data;
  shareMetadata: { title?: string; image?: string } = {
    title: '',
    image: ''
  };

  constructor(private meta: Meta, private title: Title) {}

  setMetatags(data) {
    if (data.metatags) {
      if (data.metatags.title) {
        this.title.setTitle(data.metatags.title);
      }
      if (data.metatags.description) {
        this.meta.addTag({
          name: 'description',
          content: data.metatags.description
        });
      }
      if (data.metatags.keywords) {
        this.meta.addTag({
          name: 'keywords',
          content: data.metatags.keywords
        });
      }
      if (data.metatags['og:title']) {
        this.meta.addTag({
          name: 'og:title',
          content: data.metatags['og:title']
        });
        this.meta.addTag({
          property: 'og:title',
          content: data.metatags['og:title']
        });
        this.meta.addTag({
          name: 'twitter:title',
          content: data.metatags['og:title']
        });
        this.shareMetadata.title = data.metatags['og:title'];
      }
      if (data.metatags['og:description']) {
        this.meta.addTag({
          name: 'og:description',
          content: data.metatags['og:description']
        });
        this.meta.addTag({
          property: 'og:description',
          content: data.metatags['og:description']
        });
        this.meta.addTag({
          name: 'twitter:description',
          content: data.metatags['og:title']
        });
      }
      if (data.metatags['og:image']) {
        this.meta.addTag({
          name: 'og:image',
          content: data.metatags['og:image']
        });
        this.meta.addTag({
          property: 'og:image',
          content: data.metatags['og:image']
        });
        this.meta.addTag({
          name: 'twitter:image',
          content: data.metatags['og:title']
        });
        this.shareMetadata.image = data.metatags['og:image'];
      }
    }
  }
}
