import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabsComponent } from 'src/app/components/tabs/tabs.component';

import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [TabsComponent],
  imports: [CommonModule, SharedModule],
  entryComponents: [TabsModule.rootComponent]
})
export class TabsModule {
  static rootComponent = TabsComponent;
}
