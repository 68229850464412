import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

import { FooterContentService } from '../../services/footer-content.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footer$;
  showCookieAgreement: boolean;

  constructor(
    private footerContent: FooterContentService,
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private cookie: CookieService
  ) {}

  ngOnInit() {
    this.footer$ = this.footerContent.getContent(this.translate.currentLang);

    this.translate.onLangChange.subscribe(() => {
      if (!this.changeDetectorRef['destroyed']) {
        this.footer$ = this.footerContent.getContent(
          this.translate.currentLang
        );
        this.changeDetectorRef.detectChanges();
      }
    });

    if (
      this.cookie.check('cookie_agreement') &&
      this.cookie.get('cookie_agreement') === 'true'
    ) {
      this.showCookieAgreement = false;
    } else {
      this.showCookieAgreement = true;
    }
  }

  onCookieAccept() {
    this.cookie.set(
      'cookie_agreement',
      'true',
      new Date(Date.now() + 1000 * 60 * 60 * 24)
    );
    this.showCookieAgreement = false;
  }
}
