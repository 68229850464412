import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private cache$: Observable<Object>;
  private lang: string;
  constructor(private http: HttpClient, private translate: TranslateService) {}
  private bookNowOpener = new Subject<boolean>();
  bookNowOpenerStream$ = this.bookNowOpener.asObservable();
  public language$ = new BehaviorSubject(
    this.translate.currentLang || this.translate.defaultLang || 'en'
  );

  getHeader(langKey: string) {
    if (langKey !== this.lang) {
      this.cache$ = null;

      if (!this.cache$) {
        this.cache$ = this.http
          .get(`${environment.baseURL}/${langKey}/header`)
          .pipe(shareReplay(1));
      }
    }

    this.lang = langKey;

    return this.cache$;
  }

  openBookNow() {
    this.bookNowOpener.next(true);
  }

  languageChanged(language: string) {
    this.language$.next(language);
  }
}
