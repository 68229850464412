import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { fromEvent } from 'rxjs';
import { HeaderService } from 'src/app/services/header.service';
import { BookingFormService } from '../../services/booking-form.service';
import { PopupService } from '../../services/popup.service';
import { CnSites } from '../../utils/CnSites';

@Component({
  selector: 'app-content-editor',
  templateUrl: './content-editor.component.html',
  styleUrls: ['./content-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentEditorComponent implements OnInit, AfterViewInit {
  @ViewChild('container', { read: ElementRef })
  container: ElementRef;

  @Input() data;
  cleanData: SafeHtml;
  backgroundOverlayShownClass: string;

  constructor(
    public sanitizer: DomSanitizer,
    private popupService: PopupService,
    private headerService: HeaderService,
    private bookingFormService: BookingFormService
  ) {}

  ngOnInit() {
    this.cleanData = this.data.body
      ? this.sanitizer.bypassSecurityTrustHtml(this.data.body)
      : '';
    this.setBackgroundOverlayShownClass(this.data.is_background_transparent);
  }

  ngAfterViewInit() {
    this.externalLinksTargetBlank();
    this.externalLinksTargetSelf();
    this.removeImgTitle();
    this.checkPopup();
    this.modifyImagesPathsForAlicloud();
  }

  /*
 @TODO Temporary workaround function, remove when .cn images issue will be fixed.
 Because now (we hope, temporary) image paths like "https://alicloud-site-name.cn/image.jpg" not working, (so, paths like "/sites/image.jpg" also not working)
 lets rewrite such paths to look like "https://melco-backend.com/sites/oursite/image.jpg"
 As temporary solutions
  */
  modifyImagesPathsForAlicloud() {
    const cnImageStoragePrefix = 'https://www.altiramacau.com';
    const wrongCnPathsStart = '/sites';

    if (this.container) {
      const elements: NodeList = this.container.nativeElement.querySelectorAll(
        '.content-editor img, a.sc-button, a.altira-button'
      );

      if (elements.length > 0) {
        elements.forEach((element: HTMLElement) => {
          const sourceAttributeName = element.hasAttribute('src')
            ? 'src'
            : 'href';
          const sourceAddress = element.getAttribute(sourceAttributeName);
          // URL needs rewrite only if we are on .cn site and hostname is absent (url of images usually starts with "/sites")
          if (sourceAddress) {
            if (
              sourceAddress.length > 10 &&
              sourceAddress.startsWith(wrongCnPathsStart) &&
              CnSites.isCnSiteOpened()
            ) {
              element.setAttribute(
                sourceAttributeName,
                cnImageStoragePrefix + sourceAddress
              );
            }
          }
        });
      }
    }
  }
  externalLinksTargetBlank() {
    const anchors = this.container.nativeElement.querySelectorAll(
      '.content-editor a'
    );
    if (anchors.length) {
      [].forEach.call(anchors, (anchor: HTMLLinkElement) => {
        if (
          anchor.getAttribute('href') &&
          !anchor.getAttribute('href').startsWith('tel:') &&
          !anchor.getAttribute('href').startsWith('mailto:')
        ) {
          anchor.setAttribute('target', '_blank');
        }
      });
    }
  }

  externalLinksTargetSelf() {
    const anchors = this.container.nativeElement.querySelectorAll(
      '.cookies-container .content-editor a'
    );
    if (anchors.length) {
      [].forEach.call(anchors, (anchor: HTMLLinkElement) => {
        if (anchor.getAttribute('href')) {
          anchor.setAttribute('target', '_self');
        }
      });
    }
  }

  removeImgTitle() {
    const imageEl: HTMLElement | null = this.container.nativeElement.querySelector(
      '.content-editor.is-logo img'
    );

    if (imageEl) {
      const imgTitle = imageEl.getAttribute('title');
      if (imgTitle) {
        imageEl.onmouseover = function() {
          imageEl.removeAttribute('title');
        };
        imageEl.onmouseout = function() {
          imageEl.setAttribute('title', imgTitle);
        };
      }
    }
  }

  checkPopup() {
    const popupButtons = this.container.nativeElement.querySelectorAll(
      'button[data-url]'
    );

    if (popupButtons.length) {
      [].forEach.call(popupButtons, (popupButton: HTMLButtonElement) => {
        const anchor: HTMLAnchorElement = document.createElement('a');
        const url = popupButton.getAttribute('data-url');
        anchor.innerText = popupButton.innerText;
        anchor.classList.add('altira-button');
        anchor.classList.add('altira-button--primary');
        anchor.href = 'javascript:;';
        popupButton.parentNode.insertBefore(anchor, popupButton.nextSibling);
        popupButton.parentElement.removeChild(popupButton);
        const source = fromEvent(anchor, 'click');
        if (popupButton.getAttribute('data-popup') === 'true') {
          source.subscribe($event => {
            $event.preventDefault();
            this.popupService.setPopupPage(url);
          });
        } else {
          source.subscribe($event => {
            $event.preventDefault();
            this.headerService.openBookNow();
          });
        }
      });
    }
  }

  setBackgroundOverlayShownClass(backgroundIsTransparent: boolean) {
    this.backgroundOverlayShownClass = backgroundIsTransparent
      ? ''
      : 'background-overlay';
  }

  @HostListener('click', ['$event.target'])
  click(element: HTMLLinkElement) {
    // Now we must to add a link to button element
    if (
      element.tagName === 'A' &&
      element.classList.contains('altira-button') &&
      element.classList.contains('altira-button--primary') &&
      element.classList.contains('book-now')
    ) {
      (<HTMLLinkElement>(
        element
      )).href = this.bookingFormService.getWithAdditionalParameters(
        (<HTMLLinkElement>element).href,
        true
      );
    }
    return true;
  }
}
