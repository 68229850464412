import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-top',
  templateUrl: './footer-top.component.html',
  styleUrls: ['./footer-top.component.scss']
})
export class FooterTopComponent implements OnInit {
  static soURL = 'special-offers';

  @Input() pageTypes;
  @Input() footer$;
  @Input() currentLanguage;

  public totalItems: number;
  public footerTopMenuLinks = [];

  constructor() {}

  ngOnInit() {
    this.footer$.subscribe(res => {
      this.footerTopMenuLinks = res.main_menu;
      this.totalItems = Math.round(this.footerTopMenuLinks.length / 2);
    });
  }

  getSpecialOfferLink(pageType: string) {
    return (
      '/' +
      this.currentLanguage +
      '/' +
      this.soUrl +
      decodeURIComponent('#') +
      pageType
    );
  }

  public get soUrl(): string {
    return FooterTopComponent.soURL;
  }
}
