import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BackgroundService {
  constructor() {}

  setBackground(data) {
    const backgroundProperties: any = {};

    if (data && data.background && data.background.type !== 'none') {
      const background = data.background;

      if (background['bg-image']) {
        backgroundProperties['background-repeat'] = 'no-repeat';
        backgroundProperties['background-position'] =
          background['bg-image']['img-position'];
        backgroundProperties['background-size'] =
          background['bg-image']['img-stretch'];
      }

      if (
        background['bg-image'] &&
        background['bg-color'].color1 &&
        background['bg-color'].color2
      ) {
        backgroundProperties['background-image'] = `url('${
          background['bg-image'].image
        }'), linear-gradient(${background['bg-color'].color1}, ${
          background['bg-color'].color2
        })`;
      } else if (background['bg-image'] && background['bg-color'].color1) {
        backgroundProperties['background-color'] =
          background['bg-color'].color1;
        backgroundProperties['background-image'] = `url('${
          background['bg-image'].image
        }')`;
      } else if (
        background['bg-color'].color1 &&
        background['bg-color'].color2
      ) {
        backgroundProperties['background-image'] = `linear-gradient(${
          background['bg-color'].color1
        }, ${background['bg-color'].color2})`;
      } else if (background['bg-image']) {
        backgroundProperties['background-image'] = `url('${
          background['bg-image'].image
        }')`;
      } else if (background['bg-color'].color1) {
        backgroundProperties['background-color'] =
          background['bg-color'].color1;
      }
    }
    return backgroundProperties;
  }
}
