import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() content;
  @Input() afterOpen;
  @Input() buttonDesign;
  open = false;

  // Open or Close modal
  changeState(event) {
    event.preventDefault();
    event.stopPropagation();

    this.open = !this.open;
    const modalComponent = this;

    if (this.open && this.afterOpen) {
      setTimeout(() => {
        if (this.afterOpen.length > 1) {
          modalComponent.afterOpen[0](this.afterOpen[1]);
        }
      }, 100);
    }
  }

  // Close on ESC
  @HostListener('window:keyup', ['$event'])
  close(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.open = false;
    }
  }
}
