import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  constructor(private http: HttpClient) {}

  checkHomepage(url: string, langKey: string) {
    if (
      url === '' ||
      url === '/' ||
      url === 'en' ||
      url === 'zh-hans' ||
      url === 'zh-hant'
    ) {
      return `${environment.baseURL}/${langKey}/home`;
    } else {
      return `${environment.baseURL}/${langKey}/${url}`;
    }
  }

  getPage(endpoint: string, langKey: string) {
    return this.http.get(this.checkHomepage(endpoint, langKey));
  }
}
